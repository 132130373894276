.configurator.box__fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  width: 100%; }

.configurator .box__right {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin-left: auto;
  overflow-x: hidden;
  background-color: #fff;
  box-shadow: -16px 0 40px rgba(0, 0, 0, 0.16); }
  @media all and (min-width: 992px) {
    .configurator .box__right {
      width: 662px; } }
  @media all and (min-width: 1400px) {
    .configurator .box__right {
      width: 862px; } }

.configurator .box__header {
  position: relative; }
  .configurator .box__header .back,
  .configurator .box__header .close {
    position: absolute;
    top: 25px;
    color: #373737;
    background-color: transparent;
    border: 0; }
    @media all and (min-width: 576px) {
      .configurator .box__header .back,
      .configurator .box__header .close {
        top: 46px; } }
  .configurator .box__header .back {
    left: 25px;
    font-size: 16px; }
    @media all and (min-width: 576px) {
      .configurator .box__header .back {
        left: 65px; } }
  .configurator .box__header .close {
    right: 25px;
    font-size: 20px; }
    @media all and (min-width: 576px) {
      .configurator .box__header .close {
        right: 65px; } }
  .configurator .box__header.heading--offset {
    display: block;
    padding-top: 90px; }
    @media all and (min-width: 576px) {
      .configurator .box__header.heading--offset {
        padding-top: 120px; } }

.configurator .box__heading {
  padding-right: 15px;
  padding-bottom: 30px;
  padding-left: 15px;
  margin: 0 auto;
  font-size: 28px;
  font-weight: 600;
  color: #373737;
  text-align: center; }
  @media all and (min-width: 576px) {
    .configurator .box__heading {
      padding-bottom: 66px; } }

.configurator .item__btn,
.configurator .item__line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%;
  min-height: 140px;
  padding: 0 20px;
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: 500;
  color: #373737;
  background-color: #f8f8f8;
  border: 0; }
  @media all and (min-width: 576px) {
    .configurator .item__btn,
    .configurator .item__line {
      padding: 0 20%; } }

.configurator .item__line {
  -ms-flex-align: start;
      align-items: flex-start;
  padding: 0 44px; }

.configurator .item__line--form {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 15px; }
  @media all and (min-width: 992px) {
    .configurator .item__line--form {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      padding-bottom: 0; } }
  .configurator .item__line--form .form-group {
    margin-bottom: 15px; }
    @media all and (min-width: 992px) {
      .configurator .item__line--form .form-group {
        margin-bottom: 0; } }

.configurator .box__list {
  padding-left: 0;
  list-style: none; }
  .configurator .box__list .item,
  .configurator .box__list .item__link {
    width: 100%; }
  .configurator .box__list .item__btn {
    border: 1px solid transparent;
    outline-color: transparent; }
    .configurator .box__list .item__btn.selected {
      border: 1px solid #e52b5f; }
    .configurator .box__list .item__btn .icon {
      color: #e52b5f; }
    .configurator .box__list .item__btn:hover, .configurator .box__list .item__btn:focus {
      color: #f3bfc1; }
      .configurator .box__list .item__btn:hover .icon, .configurator .box__list .item__btn:focus .icon {
        color: #f3bfc1; }
  .configurator .box__list .item__text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    margin-right: 50px;
    text-align: left; }
    .configurator .box__list .item__text small {
      margin-top: 10px; }

.configurator small {
  font-size: 14px;
  color: #5a5a5a; }

.configurator .box__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

@media all and (min-width: 992px) {
  .configurator.box__main {
    display: -ms-flexbox;
    display: flex; } }

.configurator .box__scratch {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 2;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-height: 100vh;
  padding-top: 50px;
  overflow-y: auto;
  transition-duration: .5s;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  .configurator .box__scratch.show {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  @media all and (min-width: 992px) {
    .configurator .box__scratch {
      position: static;
      padding-top: 0;
      -webkit-transform: none;
              transform: none; } }
  @media all and (min-width: 992px) {
    .configurator .box__scratch .image {
      margin-top: 50px; } }

.configurator.box__backdrop {
  background-color: rgba(0, 0, 0, 0.4); }

.configurator .position__relative {
  position: relative; }

.configurator .slide-leave-active,
.configurator .slide-enter-active {
  transition: .5s; }

.configurator .slide-enter,
.configurator .slide-leave-to {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0); }

.configurator .fade-enter-active,
.configurator .fade-leave-active {
  transition: opacity .5s; }

.configurator .fade-enter,
.configurator .fade-leave-to {
  opacity: 0; }

.configurator .bg--white {
  background-color: white; }

.configurator .sign {
  position: relative;
  width: 100%;
  margin: 0 auto; }
  @media all and (min-width: 992px) {
    .configurator .sign {
      width: 115px;
      margin-right: 27px; } }

.configurator .sign,
.configurator .form__heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700; }

.configurator .text__huge {
  font-size: 100px;
  font-weight: 700;
  color: #e4e4e4;
  text-transform: uppercase; }

.configurator .text__main {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.configurator .form-control-label {
  display: inline-block;
  margin-bottom: 16px;
  font-size: 16px; }

.configurator .form-group {
  position: relative;
  margin-bottom: 0; }

.configurator .form-group--spacing {
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 0 auto; }
  @media all and (min-width: 992px) {
    .configurator .form-group--spacing {
      margin: 0; } }

.configurator .field__tooltip {
  position: absolute;
  top: -5px;
  right: -20px;
  min-width: auto;
  background-color: #f8f8f8;
  border: 0; }
  .configurator .field__tooltip:hover, .configurator .field__tooltip:focus {
    color: #f3bfc1;
    background-color: transparent; }

.configurator .text__center {
  text-align: center; }

.configurator .form__options,
.configurator .form__values {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  margin-right: 15px;
  margin-left: 15px; }
  @media all and (min-width: 992px) {
    .configurator .form__options,
    .configurator .form__values {
      margin-right: 0;
      margin-left: 0; } }
  .configurator .form__options .form__option,
  .configurator .form__options .form__value,
  .configurator .form__values .form__option,
  .configurator .form__values .form__value {
    margin-bottom: 15px; }
    @media all and (min-width: 992px) {
      .configurator .form__options .form__option,
      .configurator .form__options .form__value,
      .configurator .form__values .form__option,
      .configurator .form__values .form__value {
        padding-right: 35px;
        padding-left: 35px;
        margin-bottom: 0; } }
  .configurator .form__options .form__label,
  .configurator .form__values .form__label {
    font-size: 16px;
    font-weight: 500;
    color: #5a5a5a; }
  .configurator .form__options .form__legend,
  .configurator .form__values .form__legend {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    max-width: 141px;
    margin-left: auto;
    text-align: right; }
    @media all and (min-width: 992px) {
      .configurator .form__options .form__legend,
      .configurator .form__values .form__legend {
        display: block;
        padding-right: 15px; } }

.configurator .form__options {
  margin-bottom: 10px; }
  @media all and (min-width: 576px) {
    .configurator .form__options .form__option {
      margin-right: 15px; } }
  @media all and (min-width: 992px) {
    .configurator .form__options .form__option {
      margin-right: 0; } }

.configurator .form__values {
  margin-bottom: 25px; }
  @media all and (min-width: 992px) {
    .configurator .form__values {
      margin-bottom: 0; } }
  .configurator .form__values .form-control {
    max-width: 141px; }
  .configurator .form__values .form__value {
    -ms-flex-item-align: start;
        align-self: flex-start;
    text-align: center; }
    .configurator .form__values .form__value .form-inline {
      margin-bottom: 24px; }
    .configurator .form__values .form__value .invalid-feedback--form {
      left: 0; }
    .configurator .form__values .form__value .invalid-feedback--two-pds {
      max-width: 141px;
      margin-left: auto; }

.configurator .form__heading {
  margin-top: 25px;
  margin-bottom: 13px; }

.configurator .form__description {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 50px;
  text-align: center; }
  @media all and (min-width: 992px) {
    .configurator .form__description {
      padding-right: 0;
      padding-left: 0; } }

.configurator .form-inline {
  position: relative; }
  .configurator .form-inline label {
    -ms-flex-pack: start;
        justify-content: flex-start;
    min-width: 90px; }
  .configurator .form-inline .form__label {
    margin-right: 15px;
    margin-bottom: 0; }
    @media all and (min-width: 992px) {
      .configurator .form-inline .form__label {
        margin-right: 0; } }

.configurator .text--dark-pink {
  color: #e52b5f !important; }

.configurator .text--red {
  color: #d54343 !important; }

.configurator .border--red {
  border-color: #d54343 !important; }

.configurator .semi-bold {
  font-weight: 600 !important; }

.configurator .form__footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-right: 15px;
  margin-bottom: 60px;
  margin-left: 15px; }
  @media all and (min-width: 576px) {
    .configurator .form__footer {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }
  @media all and (min-width: 992px) {
    .configurator .form__footer {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-top: 30px;
      margin-right: 25px;
      margin-bottom: 0;
      margin-left: 25px; } }
  @media all and (min-width: 1400px) {
    .configurator .form__footer {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      margin-right: 74px;
      margin-left: 74px; } }
  .configurator .form__footer .form__label {
    margin-bottom: 15px; }
    @media all and (min-width: 1400px) {
      .configurator .form__footer .form__label {
        margin-bottom: 0; } }

.configurator .box__heading--form {
  padding-bottom: 40px; }

.configurator .invalid-feedback--form {
  position: relative;
  top: 100%;
  left: 0;
  display: block;
  font-size: 12px;
  font-weight: 400; }

.configurator .heading--tiles {
  margin-bottom: 15px;
  font-size: 20px; }

.configurator .box--spacing {
  padding: 15px; }
  @media all and (min-width: 992px) {
    .configurator .box--spacing {
      padding: 73px; } }

.configurator .box__tiles {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 -25px; }
  @media all and (min-width: 1400px) {
    .configurator .box__tiles {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }
  .configurator .box__tiles .item {
    margin: 5px 25px; }

.configurator .tile {
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  min-width: 180px;
  min-height: 65px;
  padding: 20px;
  margin: 0; }
  .configurator .tile .icon {
    display: none; }
  .configurator .tile .item__text {
    margin-right: 0;
    text-align: center;
    text-transform: uppercase; }

.configurator .box__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: calc(100vh - 193px); }

.configurator .box__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center; }

.configurator .box__heading--sm {
  padding-top: 40px;
  padding-bottom: 20px; }

.configurator .left__side {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  max-width: 100%; }
  @media all and (min-width: 992px) {
    .configurator .left__side {
      margin-right: 30px; } }
  @media all and (min-width: 1700px) {
    .configurator .left__side {
      max-width: 60%; } }

.configurator .right__side {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 15px; }
  @media all and (min-width: 1700px) {
    .configurator .right__side {
      max-width: calc(40% - 30px);
      margin-bottom: 0; } }

.configurator .no-prescription {
  padding: 70px 15px 50px; }
  @media all and (min-width: 992px) {
    .configurator .no-prescription {
      padding: 50px 30px; } }
  .configurator .no-prescription .heading {
    margin-bottom: 21px;
    font-size: 20px;
    color: #e52b5f; }
  .configurator .no-prescription .description {
    margin-bottom: 30px; }
  .configurator .no-prescription .list__no {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-pack: center;
        justify-content: center;
    width: 44px;
    height: 44px;
    margin-right: 12px;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    color: #e52b5f;
    border: 2px solid #373737;
    border-radius: 100%; }
  .configurator .no-prescription .images_list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-left: 0;
    list-style: none; }
    .configurator .no-prescription .images_list .item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: justify;
          justify-content: space-between;
      width: 100%;
      padding: 15px 0; }
      @media all and (min-width: 992px) {
        .configurator .no-prescription .images_list .item {
          width: auto;
          padding: 15px; } }
      @media all and (min-width: 1400px) {
        .configurator .no-prescription .images_list .item {
          width: 50%; } }
      @media all and (min-width: 1700px) {
        .configurator .no-prescription .images_list .item {
          width: 33.33%; } }
  .configurator .no-prescription .item__text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start; }

.configurator .form__additional-text {
  display: block;
  font-size: 12px;
  line-height: 1; }

.configurator .scratch__toggler {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 50px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0; }
  @media all and (min-width: 992px) {
    .configurator .scratch__toggler {
      display: none; } }

.select--sign {
  width: 70px; }
  .select--sign + .form-control {
    padding-right: 10px !important;
    padding-left: 10px !important; }

.select2-selection__rendered {
  padding: 0 10px !important; }

.select2-selection__arrow {
  right: 5px !important; }
